// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleps{ 
margin-top: 8px;
font-size: 16px; 
}

p{
    margin: 0;
}

.idproof_country {
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 7px;
    background:#fff;	
}
.top_div{
    width: 100vw;
    padding: 0;
    font-size: 0.9rem;
}

.idproof {
    border: 1px solid #ddd;
    margin-bottom: 5px;
    border-radius: 7px;
    background:#fdfdfd;
    cursor:pointer;	
}
.idproof:hover, .idproof:active{
    border: 1px solid #ddd;	
    background:#f4f4f4;	
}
.divround {
    background: #f2f2f2;
    width: 50px;
    border-radius: 50px;
    height: 50px;
    padding: 0px;
    margin: 12px 4px;
    padding: 13px;   
}

.collect-steps_1{
    display: flex;
    justify-content: left;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 4px;
}

.uls{ 
padding-left:13px; 
}
.passport-photos{ 
margin-top:20px; 
}
.lbn{ 
margin-top:20px; 
}`, "",{"version":3,"sources":["webpack://./src/pages/idproof.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf,eAAe;AACf;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;AAClB;AACA;IACI,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;AACA,iBAAiB;AACjB;AACA;AACA,eAAe;AACf;AACA;AACA,eAAe;AACf","sourcesContent":[".titleps{ \nmargin-top: 8px;\nfont-size: 16px; \n}\n\np{\n    margin: 0;\n}\n\n.idproof_country {\n    border: 1px solid #ddd;\n    margin-bottom: 5px;\n    border-radius: 7px;\n    background:#fff;\t\n}\n.top_div{\n    width: 100vw;\n    padding: 0;\n    font-size: 0.9rem;\n}\n\n.idproof {\n    border: 1px solid #ddd;\n    margin-bottom: 5px;\n    border-radius: 7px;\n    background:#fdfdfd;\n    cursor:pointer;\t\n}\n.idproof:hover, .idproof:active{\n    border: 1px solid #ddd;\t\n    background:#f4f4f4;\t\n}\n.divround {\n    background: #f2f2f2;\n    width: 50px;\n    border-radius: 50px;\n    height: 50px;\n    padding: 0px;\n    margin: 12px 4px;\n    padding: 13px;   \n}\n\n.collect-steps_1{\n    display: flex;\n    justify-content: left;\n    flex-direction: column;\n    margin-top: 8px;\n    margin-bottom: 4px;\n}\n\n.uls{ \npadding-left:13px; \n}\n.passport-photos{ \nmargin-top:20px; \n}\n.lbn{ \nmargin-top:20px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
