import React ,{ useEffect, useState } from "react" ;
import {Link}  from 'react-router-dom'
const Donepage = () => {
	const [isMobile, setIsMobile] = useState(false);
	const brandlogo = localStorage.getItem('brandlogo');
	const checkIfMobile = () => {
		if (window.innerWidth <= 768) {
		  return true; 
		}
		return false;
	  };

	  const updateMedia = () => {
		setIsMobile(checkIfMobile());
	  };
	
	
	  useEffect(() => {
		updateMedia();
		window.addEventListener('resize', updateMedia);
		return () => {
		  window.removeEventListener('resize', updateMedia);
		};
	  }, []);
	  useEffect(() => {
	  }, [isMobile]);
    return (
		
	<div className="container-fluid">
		 {!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}
	  {isMobile && (
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					
					<div className="logo_div text-center">
						<img src={brandlogo} className="img-responsive logo_img"/>
					</div>
					<div className="">
						<div className="card_c">
							<div className="card-header text-center">							
								<h2 className="card_head">Thank you!</h2>								
							</div>
							<div className="card-body success_div">
								<img src="./icon/checked.png" className="success"/>
								<div className="text-center">	
									<br/>
									<p>Your verification status will update automatically.</p>
								</div>
							</div>
							{/* <div className="container_btn">
								<div className="btn btn-primary">	
									<span className="btn_text">Go Back <i class="fa-solid fa-arrow-left"></i></span>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
		)}
	</div>
	);
};
export default Donepage;