import {React,  useState ,useEffect,useRef} from "react";
import { useNavigate  } from "react-router-dom";
import './idproof.css';
import 'bootstrap/dist/css/bootstrap.css';


const IdproofType = () => {
	const inputRef = useRef(null);
	const brandname = localStorage.getItem('brandname');
	const brandlogo = localStorage.getItem('brandlogo');
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(false);
	const checkIfMobile = () => {
		if (window.innerWidth <= 768) {
		  return true; 
		}
		return false;
	  };
	  const updateMedia = () => {
		setIsMobile(checkIfMobile());
	  };
	
	  useEffect(() => {
		updateMedia();
		window.addEventListener('resize', updateMedia);
		return () => {
		  window.removeEventListener('resize', updateMedia);
		};
	  }, []);
	
	
	const apicountryname = localStorage.getItem('setapicountryname');
	const passport = localStorage.getItem('setapipassport');
	const idcard = localStorage.getItem('setidcard');
	const driverlicense = localStorage.getItem('setdriverlicense');
	const permitresidence = localStorage.getItem('setpermitresidence');

	const nextpage = (e) => {
		if (e === 'Passport') {
		  localStorage.setItem('front_back', 0);
		  localStorage.setItem('id_image', './icon/example.png');
		  localStorage.removeItem('id_image_back');
		} else if (e === 'Driver License') {
		  localStorage.setItem('front_back', localStorage.getItem('driver_license_type'));
		  localStorage.setItem('id_image', './icon/driver_address.png');
		  localStorage.setItem('id_image_back', './icon/driver_address.png');
		} else if (e === 'ID Proof') {
		  localStorage.setItem('front_back', localStorage.getItem('idcard_type'));
		  localStorage.setItem('id_image', './icon/driver_address.png');
		  localStorage.setItem('id_image_back', '../icon/driver_address.png');
		} else if (e === 'Residence Permit') {
		  localStorage.setItem('front_back', localStorage.getItem('permit_residence_type'));
		  localStorage.setItem('id_image', './icon/ResidencePermit-front.png');
		  localStorage.setItem('id_image_back', './icon/ResidencePermit-back.png');
		}
		
		localStorage.setItem('setdocumenttitle', e);
		navigate('/Passport');
	  };
	  
	
    useEffect(() => {
		let uuids = localStorage.getItem('uuid');	
		if(uuids === null) 
		{	
			navigate('/');
		}		
    }, [])
	useEffect(() => {
		
	  }, [isMobile]);
    return(
	<div className="container-fluid">
		{!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
		  <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}
	  {isMobile && (
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					
						<div className="logo_div text-center" style={{display: 'none'}}>
							<img src={brandlogo} className="img-responsive logo_img"/>
						</div>
						
						<div className="card">
							<div className="card-header text-center">							
								<div className="title">Choose your document type</div>
								<p></p>								
								<small> </small>
							</div>							
							<div className="card-body">
								<p style={{ margin:'0px'}} >Document issue by country </p>
								<div className="collect-steps">									
									<div className="row idproof_country">										
										<div className="col-12">														
											<h3 className="titleps" style={{padding:'10px'}}>{apicountryname}</h3>
										</div>
									</div>
								</div>									
								<p style={{ margin:'12px 0px -7px 0px'}}>Accepted Document</p>
																
								<div className="collect-steps">
									
									{ passport == 1 ? <div className="row idproof" onClick={() => nextpage('Passport') }>
										<div className="col-3">	
											<div className="divround"><img src="./icon/passport.png" className="listicon"/></div>
										</div>
										<div className="col-9">														
											<h3 className="titleps">Passport</h3>
											<p>Photo Page</p>
										</div>
									</div> : '' }
									{ driverlicense == 1 ? <div className="row idproof" onClick={() => nextpage('Driver License') }>
										<div className="col-3">	
											<div className="divround"><img src="./icon/driving-license.png" className="listicon"/></div>
										</div>
										<div className="col-9">														
											<h3 className="titleps">Driver's License</h3>
											<p>Front Photo</p>
										</div>
									</div> : '' }
									{ idcard == 1 ? <div className="row idproof" onClick={() => nextpage('ID Proof') }>
										<div className="col-3">	
											<div className="divround"><img src="./icon/id-card.png" className="listicon"/></div>
										</div>
										<div className="col-9">														
											<h3 className="titleps">ID Proof</h3>
											<p>Front Photo</p>
										</div>
									</div> : '' }
									{ permitresidence == 1 ? <div className="row idproof" onClick={() => nextpage('Residence Permit') }>
										<div className="col-3">	
											<div className="divround"><img src="./icon/green-card.png" className="listicon"/></div>
										</div>
										<div className="col-9">														
											<h3 className="titleps">Residence Permit</h3>
											<p>Front Photo</p>
										</div>
									</div> : '' }
																
								</div>
								
							</div>
						</div>
						<div className="row footer">	
							<div className="collect-steps_1">{brandname}</div>
						</div>
					
				</div>
			</div>
		</div>
		)}
	</div>
	);
}
export default IdproofType;