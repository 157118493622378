import React, { useRef, useEffect, useState } from 'react';
import { FaceLivenessWebComponent } from '@regulaforensics/vp-frontend-face-components';
import { useNavigate } from "react-router-dom";
import   axios from "axios";

const containerStyle = {
  display: 'flex',  
  position: 'fixed', 
  top: 20,
  left: 0,
  height: '100vh', 
  width: '100vw',  
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999, 
};


const buttonStyle = {
  padding: '10px 20px',
  color: 'white',
  fontSize: '16px',
  borderRadius: '2px',
  backgroundColor: '#0d6efd',
  border: '1px solid #0d6efd',
  cursor: 'pointer',
  fontWeight: '500',
  borderRadius: '8px',
  position:'absolute',
  top:'400px'
};

const Recognition = () => {
	const brandlogo = localStorage.getItem('brandlogo');
	const videoRef = useRef(null);
	const photoRef = useRef(null);
	const [happy, sethappy] = useState(false);
	let happypec = 0;
	let distance = 0;
	let matcher  =0;
	let unmatcher  =0;
	const [rightside, setrightside] = useState(false);
	const [leftside, setleftside] = useState(false); 
	const [passportPhotodata, setpassportPhotodata] = useState('');  
	let passportPhoto = localStorage.getItem('passportPhoto');  
	const [smile, setsmile] = useState(false);
	const [started, setstarted] = useState(false);
	const [startedbtn, setstartedbtn] = useState(false);
	const [done, setDone] = useState(false);
	const [saved, setSaved] = useState(true);
	let right =false;
	let left = false ;
	const [Rightdirct, SetphotoRight] = useState('');
	const [match, setmatch] = useState('');
	const [Distance, setdistance] = useState('');
	const [leftdirct, SetphotoLeft] = useState('');
	const [Smilephoto, setsmillephoto] = useState(''); 
	const address_proof = localStorage.getItem('address_proof');
	
	const [btn , setbtn] = useState("btn btn-primary");
	const [btntxt , setbtntxt] = useState("Start Now");
	const [disabledbtn, setdisabled] = useState(false);
	
	const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef(null);
	const componentRef = useRef(null);
	const [livenessResult, setLivenessResult] = useState(null);
	
	const navigate = useNavigate();
	let  uuid;
	  
	let Selectedcountry ,ipAdress,passportName,passportsurname,birthday,passportcountry,Gender,passportNumber,passportexpire,proofName,proofIssuerAddress,proofIsuueName,proofReciptAddress,proofDate,proofPhoto,proofDate_1,userAgent,passportPhoto_back,setdocumenttitle;
	
	const getData =()=>{
		setdocumenttitle = localStorage.getItem('setdocumenttitle');
		userAgent = localStorage.getItem('userAgent');
		Selectedcountry = localStorage.getItem('Selected-country');
		ipAdress = localStorage.getItem('Ip-Adrees');		
		passportPhoto = localStorage.getItem('passportPhoto');
		passportPhoto_back = localStorage.getItem('passportPhoto_back');
		uuid = localStorage.getItem('uuid');
		proofPhoto = localStorage.getItem('proofPhoto');
	}
   	
	const listener = (data) => {
	
    if (data.detail.action === 'PROCESS_FINISHED') 
	{
			if((data.detail.data.status === 1 && data.detail.data.response)) 
			{
				getData();				
				let postdata = JSON.stringify({					
					"type":setdocumenttitle,
					"country": Selectedcountry,					
					"uuid":uuid,
					"passportPhoto": passportPhoto,
					"passportPhoto_back": passportPhoto_back,
					"proofphoto":proofPhoto,
					"userAgent": userAgent,				
					"ip_address": ipAdress,	
					"rightsidephot": '',
					"leftsidephoto": '',
					"match": '',
					"distance": '',			
					"smilephoto": data.detail.data.response.capture[0]				
				});
				let config = {
					method: 'post',
					'mode':'cors',
					url: 'https://kyc2.codegotech.com/send_profile',
					headers: {
						'Content-Type': 'application/json'
					},
					data : postdata
				};
				
				axios.request(config).then((response) => {
						localStorage.removeItem('setdocumenttitle');
						localStorage.removeItem('userAgent');
						localStorage.removeItem('Selected-country');
						localStorage.removeItem('Ip-Adrees');
						localStorage.removeItem('proofPhoto');
						localStorage.removeItem('passportPhoto');
						localStorage.removeItem('uuid');
						
						localStorage.removeItem('setapipassport');
						localStorage.removeItem('setapicountryname');
						localStorage.removeItem('setidcard');
						localStorage.removeItem('setdriverlicense');
						localStorage.removeItem('setpermitresidence');
						
						localStorage.removeItem('front_back');
						localStorage.removeItem('passport_type');
						localStorage.removeItem('idcard_type');
						localStorage.removeItem('driver_license_type');
						localStorage.removeItem('permit_residence_type');
						localStorage.removeItem('passportPhoto_back');
						
						localStorage.removeItem('address_proof');
						navigate('/process');			
				}).catch((error) => {
					let mrz_data_element = document.getElementById('div_msgs');
					mrz_data_element.innerHTML = error;
				}); 
			}else{
				
			} 			
    }

    if (data.detail?.action === 'CLOSE' || data.detail?.action === 'RETRY_COUNTER_EXCEEDED') {
      setIsOpen(false);
    }
	};	
	
	
	useEffect(() => {
	
		
		
		if (isOpen && componentRef.current) {
		  componentRef.current.settings = {
			locale: 'en',
			videoRecording: false,  
			//url: 'https://faceapi.regulaforensics.com/',  
			url: 'https://facesdk.codegotech.com/',  
			headers: {
				Authorization: 'Basic QWxhZGRpbjpvcGVuIHNlc2FtZQ==',
			},
			tag: '123',
			customization: {			
				fontFamily: 'Noto Sans, sans-serif',
				fontSize: '16px',
				onboardingScreenStartButtonBackground: '#141774',
				onboardingScreenStartButtonBackgroundHover: '#7c45b4',
				onboardingScreenStartButtonTitle: '#FFFFFF',
				onboardingScreenStartButtonTitleHover: '#FFFFFF',
				cameraScreenFrontHintLabelBackground: '#E8E8E8',
				//onboardingScreenIllumination: 'https://path-to-image.com',
				//onboardingScreenAccessories: 'data:image/svg+xml;base64,PHN2...',
				//onboardingScreenCameraLevel: importedImage,
				cameraScreenFrontHintLabelText: '#000000',
				cameraScreenSectorActive: '#141774',
				cameraScreenSectorTarget: '#BEABE2',
				cameraScreenStrokeNormal: '#141774',
				processingScreenProgress: '#141774',
				retryScreenRetryButtonBackground: '#141774',
				retryScreenRetryButtonBackgroundHover: '#141774',
				retryScreenRetryButtonTitle: '#FFFFFF',
				retryScreenRetryButtonTitleHover: '#FFFFFF',
			},
		  };
		}
	}, [isOpen]);

  useEffect(() => {
	  
	  //load();
	let uuids = localStorage.getItem('uuid');	
	if(uuids === null) 
	{			
	  //console.log(uuids);
	  navigate('/');
	} 
	
    const containerCurrent = containerRef.current;

    if (!containerCurrent) return;

    containerCurrent.addEventListener('face-capture', listener);

    return () => {
      containerCurrent.removeEventListener('face-capture', listener);
    };
  }, []);
	return (
    <div className="container-fluid liveness">
		<div className="row">
			<div id="div_msgs"></div>
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					
					<div className="logo_div text-center" style={{display: 'none'}}>
						<img src={brandlogo} className="img-responsive logo_img" />
					</div>						
					<div className="card">
						<div className="card-headers text-center">	
            <div className="container_logoLive">
						    <img src="./icon/face_screen.png" className="logo_live" />
					  </div>
							<div className="title_head">Liveness</div>							
							{!isOpen && <p className="title">Scan your face to verify your identity</p>}						
							<div style={containerStyle} ref={containerRef}>
							{isOpen ? (
							  <face-capture ref={componentRef} ></face-capture>
							) : (
							  <button style={buttonStyle} onClick={() => setIsOpen(true)}>
								Start Liveness <i className={btnicon}></i>
							  </button>
							)}
							</div>							
						</div>							
					</div>	
					
				</div>
			</div>
		</div>
	</div>
	);
};
export default Recognition;
