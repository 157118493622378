// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.container-fluid{
  display: flex;
  justify-content: center;
  text-align: center;
}
.btn:hover{ background-color:#0a56c6 !important; border-color:#0a56c6 !important;}
.btn-primary{ padding:10px !important;}	
.btn-info{ padding:18px;}
.btn-success{ padding:18px;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{ outline:none !important; font-family: 'Roboto', sans-serif;}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;AACA,YAAY,mCAAmC,EAAE,+BAA+B,CAAC;AACjF,cAAc,uBAAuB,CAAC;AACtC,WAAW,YAAY,CAAC;AACxB,cAAc,YAAY,CAAC;AAC3B;EACE;aACW;AACb;AACA,wBAAwB,uBAAuB,EAAE,iCAAiC,CAAC","sourcesContent":["\n.container-fluid{\n  display: flex;\n  justify-content: center;\n  text-align: center;\n}\n.btn:hover{ background-color:#0a56c6 !important; border-color:#0a56c6 !important;}\n.btn-primary{ padding:10px !important;}\t\n.btn-info{ padding:18px;}\n.btn-success{ padding:18px;}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\nh1, h2, h3, h4, h5, h6{ outline:none !important; font-family: 'Roboto', sans-serif;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
