import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import axios from 'axios';

const Passport = () => {
    const webcamRef = useRef(null);
    const brandname = localStorage.getItem('brandname');
    const [idimageSrc, setImageSrc] = useState(null);
    const [errorMessage , seterrorMessage ] = useState();
    const [uploading, setUploading] = useState('');
    const navigate = useNavigate();	
    const setdocumenttitle = localStorage.getItem('setdocumenttitle');
    const address_proof = localStorage.getItem('address_proof');
    const front_back = localStorage.getItem('front_back');
    const [isMobile, setIsMobile] = useState(false);
    const [visible, setVisible] = useState(false);
    const [selvisible, setSelvisible] = useState(true);

    let textMsg = '';
    if (setdocumenttitle === 'Passport') {
        textMsg = 'We will not accept your passport if the image is not vertical.';
    }

    const checkIfMobile = () => {
        return window.innerWidth <= 768;
    };
    
    const updateMedia = () => {
        setIsMobile(checkIfMobile());
    };
    
    useEffect(() => {
        updateMedia();
        window.addEventListener('resize', updateMedia);
        return () => {
          window.removeEventListener('resize', updateMedia);
        };
    }, []);

    const capture = () => {
        const idimageSrc = webcamRef.current.getScreenshot({width: 720, height: 1280});
        setImageSrc(idimageSrc);
       
    };

    const handleUpload = async (event) => {
        if (idimageSrc) {
			 event.currentTarget.disabled = true;
			 setUploading('Uploading...');
            try {
                const datas = JSON.stringify({
                    "uuid": localStorage.getItem('uuid'),
                    "image": idimageSrc,
                    "type": setdocumenttitle,
                });
    
                const config = {
                    method: 'post',
                    url: 'https://kyc2.codegotech.com/save_image',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: datas
                };
    
                // Prima richiesta: Upload immagine
                const response = await axios.request(config);
                console.log('Upload response:', response);
    
                if (response.status === 200) {
                    if (response.data.status === 1) {
                        if (response.data.sizestatus === 1 || setdocumenttitle !== 'Passport') {
                            const front_backs = localStorage.getItem('front_back');
                            const kyctype = localStorage.getItem('kyctype');
                            const partial_page = localStorage.getItem('partial_page');
                            const address_proof = localStorage.getItem('address_proof');
    
                            if (front_backs === '1') {
                                localStorage.setItem('passportPhoto', response.data.image);
                                navigate('/idproofback');
                            } else {
                                if (kyctype === 'partial' && partial_page === 'ID') {
                                    const userData = JSON.stringify({
                                        "type": setdocumenttitle,
                                        "uuid": localStorage.getItem('uuid'),
                                        "kyctype": kyctype,
                                        "partial_page": partial_page,
                                        "userAgent": localStorage.getItem('userAgent'),
                                        "ip_address": localStorage.getItem('Ip-Adrees'),
                                        "passportPhoto": response.data.image
                                    });
    
                                    const userConfig = {
                                        method: 'post',
                                        url: 'https://kyc2.codegotech.com/send_userdata',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        data: userData
                                    };
    
                                    // Seconda richiesta: Inviare dati utente
                                    const userResponse = await axios.request(userConfig);
                                    console.log('Send user data response:', userResponse);
    
                                    if (userResponse.status === 200) {
                                        localStorage.removeItem('userAgent');
                                        localStorage.removeItem('Ip-Adrees');
                                        localStorage.removeItem('passportphoto');
                                        localStorage.removeItem('uuid');
                                        navigate('/process');
                                    }
                                } else {
                                    localStorage.setItem('passportPhoto', response.data.image);
                                    if (address_proof === '1') {
                                        navigate('/proof');
                                    } else {
                                        navigate('/Recognition');
                                    }
                                }
                            }
                        } else {
                            setVisible(false);
                            setSelvisible(true);
                            const html = 'Please re-upload passport image. Image should be vertical position';
                            document.getElementById('mrz-data').innerHTML = html;
                        }
                    } else {
                        setVisible(false);
                        setSelvisible(true);
                        const html = 'Please re-upload passport image.';
                        document.getElementById('mrz-data').innerHTML = html;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    
    
    

    useEffect(() => {
   
    }, [isMobile]);

    return (
        <div className="container-fluid">
            {!isMobile && (  
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="alert-warning" >
                            <img src='./icon/close_icon.webp' alt="Codego warning sign" />
                        </div>
                        <div className="alert-warning">
                            This application is only accessible from mobile devices. Please switch to a mobile device to continue.
                        </div>
                    </div>
                </div>
            )}
            {isMobile && ( 
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="row top_div">
                            <div className="card">
                                <div className="card-header text-center">
                                    <div className="title">{setdocumenttitle} Picture</div>
                                    <p>{front_back === '1' ? 'Front side' : '' }</p>
                                    <small>Make sure that all the information on the photo is visible and easy to read, and that all corners are visible</small>
                                </div>
                                <div className="card-body text-center">
                                    <div className="bg_camera proof">
                                        {idimageSrc ? (
                                            <div className="box-btn">
                                                <img src={idimageSrc} alt="Captured" className="img-fluid" />
												<p style = {{color: "red"}} >{uploading}</p>
                                                <button onClick={() => setImageSrc(null)} className="btn_retake">Retake</button>
                                                <button onClick={handleUpload} disabled={!idimageSrc} className="btn_retake upload">Submit</button>
												
                                            </div>
                                        ) : (
                                            <>
                                                <Webcam
                                                    audio={false}
                                                    ref={webcamRef}
                                                    screenshotFormat="image/jpg"
                                                    videoConstraints={{
                                                        width: 1280, height: 720,
                                                        facingMode: "environment"
                                                    }}
                                                    className="webcam proof"
                                                    width={1280}
                                                    height={720}
													style = {{width: "100%", height: "100%", position: "absolute", left: "50%", marginLeft: "-50%", objectFit: "cover", objectPosition: "center"}}
                                                />
                                                <div className="circle_btn">
                                                    <button onClick={capture} className="camera proof"></button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="passport-photo">
                                        <div id="row">
                                            <div className="example_passport">
                                                
                                                <p>Example {setdocumenttitle} Picture</p>
                                                <img src={localStorage.getItem('id_image')} className="example_pic" alt="passport" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id='mrz-data'>{errorMessage}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row footer">
                            <div className="collect-steps_1">{brandname}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Passport;
