// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kyc_image{
    padding: 28px 0px;
}

ul.intr li{
    font-size: 14px;
    text-align: left;
    margin: 10px 0;
}

.step-row{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 8px 0;
    text-align: start;
}


.steps p {
    font-size: 15px;
    color: #73737399;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    line-height: 20px;
    margin-left: 36px;
}
.steps h3{
	margin:  10px ;
	font-size: 14px;
	color: #20252CFF;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 20px;
}
.listicon{
    width:16px;
    margin-left: 5px;
}
.title_head{
    padding: 0px 36px 0px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
}
.title_step{
    margin-top: 8px;
    font-size: 16px;
    vertical-align: middle;
    margin-left: 2px;
}

.footer {
	position: fixed;
	bottom: 10px;
	text-align: center;
	color: #000;
	font-size: 14px;
	font-weight: 700;
	width: 100%;
	padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/intro/Intro.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;IACV,iBAAiB;AACrB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iCAAiC;IACjC,iBAAiB;IACjB,iBAAiB;AACrB;AACA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,gBAAgB;CAChB,iCAAiC;CACjC,iBAAiB;AAClB;AACA;IACI,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;CACC,eAAe;CACf,YAAY;CACZ,kBAAkB;CAClB,WAAW;CACX,eAAe;CACf,gBAAgB;CAChB,WAAW;CACX,YAAY;AACb","sourcesContent":[".kyc_image{\n    padding: 28px 0px;\n}\n\nul.intr li{\n    font-size: 14px;\n    text-align: left;\n    margin: 10px 0;\n}\n\n.step-row{\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin: 8px 0;\n    text-align: start;\n}\n\n\n.steps p {\n    font-size: 15px;\n    color: #73737399;\n    font-weight: 500;\n    font-family: 'Roboto', sans-serif;\n    line-height: 20px;\n    margin-left: 36px;\n}\n.steps h3{\n\tmargin:  10px ;\n\tfont-size: 14px;\n\tcolor: #20252CFF;\n\tfont-weight: 600;\n\tfont-family: 'Roboto', sans-serif;\n\tline-height: 20px;\n}\n.listicon{\n    width:16px;\n    margin-left: 5px;\n}\n.title_head{\n    padding: 0px 36px 0px;\n    font-size: 24px;\n    font-weight: 400;\n    text-align: center;\n    margin-bottom: 20px;\n}\n.title_step{\n    margin-top: 8px;\n    font-size: 16px;\n    vertical-align: middle;\n    margin-left: 2px;\n}\n\n.footer {\n\tposition: fixed;\n\tbottom: 10px;\n\ttext-align: center;\n\tcolor: #000;\n\tfont-size: 14px;\n\tfont-weight: 700;\n\twidth: 100%;\n\tpadding: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
