import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import ReactFlagsSelect from "react-flags-select";

const Home = () => {
  const [countrystate, setCountry] = useState("");
  const brandname = localStorage.getItem('brandname');
  const brandlogo = localStorage.getItem('brandlogo');
  const [value, setValue] = useState(null);
  const [selected, setSelected] = useState("");
  const [disabledbtn, setDisabled] = useState(false);
  const [btn, setBtn] = useState("btn btn-primary");
  const [btntxt, setBtnTxt] = useState("Continue");
  const [btnicon, setBtnIcon] = useState("fa-solid fa-arrow-right");
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();

 
  const checkIfMobile = () => {
    if (window.innerWidth <= 768) {
      return true; 
    }
    return false;
  };
  const updateMedia = () => {
    setIsMobile(checkIfMobile());
  };
  useEffect(() => {
    updateMedia();
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, []);
  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      localStorage.setItem('Ip-Adrees', res.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };
  useEffect(() => {
    if (selected !== '') {
      localStorage.setItem('Selected-country', selected);
    }
  }, [selected]);

  const nextpage = async () => {
    setBtnTxt("");
    setDisabled(true);
    setBtn("btn btn-success");
    setBtnIcon("fa-solid fa-check");

    const uuid = localStorage.getItem('uuid');
    const country = localStorage.getItem('Selected-country');

    let data = JSON.stringify({
      "uuid": uuid,
      "country": country,
    });

    let config = {
      method: 'POST',
      mode: 'cors',
      url: 'https://kyc2.codegotech.com/check_api_idproof',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        if (response.data.status === 1) {
          localStorage.setItem('setapicountryname', response.data.countryname);
          localStorage.setItem('setapipassport', 1);
          localStorage.setItem('setidcard', response.data.idcard);
          localStorage.setItem('setdriverlicense', response.data.driver_license);
          localStorage.setItem('setpermitresidence', 0);
          localStorage.setItem('passport_type', 0);
          localStorage.setItem('idcard_type', response.data.idcard_type);
          localStorage.setItem('driver_license_type', response.data.driver_license_type);
          localStorage.setItem('permit_residence_type', 0);
          navigate('/idprooftype');
        } else {
          navigate('/Passport');
        }
      }
    } catch (error) {
      console.error("Error during API request:", error);
    }
  };
  useEffect(() => {
    let uuids = localStorage.getItem('uuid');
    if (uuids === null) {
      navigate('/');
    }
  }, [navigate]);

  
  useEffect(() => {
    
  }, [isMobile]);

  return (
    <div className="container-fluid">
      {!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
		  <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}

      {isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="row top_div">
              <div className="logo_div text-center" style={{ display: 'none' }}>
                <img src={brandlogo} className="img-responsive logo_img" alt="Logo" />
              </div>

              <div className="cards">
                <div className="card-header text-center">
                  <div className="title">IDENTITY DOCUMENT COUNTRY</div>
                  <p></p>
                </div>

                <div className="card-body">
                  <div className="select_div">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Select the country that issued your document</label>
                    <div className="selectarea">
                    <ReactFlagsSelect
                      value={value}
                      searchable
                      selected={selected}
                      flush={true}
                      onSelect={(code) => setSelected(code)}
                    />

                    </div>
                  </div>
                </div>
                {selected && (
                  <div className="select_btn home_btn">
                    <button className="btn_continue" disabled={disabledbtn} onClick={nextpage} style={{width:'100%'}}>
                      {btntxt} <i className={btnicon}></i>
                    </button>
                  </div>
                )}
              </div>

              <div className="row footer">
                <div className="collect-steps_1">{brandname}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
