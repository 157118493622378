import React ,{ useState } from "react" ;
import { useNavigate  } from "react-router-dom";
import './Intro.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const brandname = localStorage.getItem('brandname');

const Intro = () => {
	
	const [btn , setbtn] = useState("btn btn-primary");
	const [btntxt , setbtntxt] = useState("Continue");
	const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
	const brandlogo = localStorage.getItem('brandlogo');
	
    const navigate = useNavigate();
	
	
	
    const nextpage= ()=>{
		setbtntxt("");
		setbtn("btn btn-success");
		setbtnicon("fa-solid fa-check");
		
		const isalreadycheck = localStorage.getItem('isalreadycheck');	
		
		const kyctype = localStorage.getItem('kyctype');
		
		if(kyctype === 'partial')
		{
			const partial_page = localStorage.getItem('partial_page');
			if(partial_page === 'LIVENESS')
			{
				setTimeout(function(){	navigate('/liveness') }, 500);				
			}else if(partial_page === 'ID')
			{
				setTimeout(function(){	navigate('/Passport') }, 500);
			}else if(partial_page === 'ADDRESS')
			{
				setTimeout(function(){	navigate('/proof') }, 500);
			}
			
		}else{
			if(isalreadycheck == 0)
			{
				setTimeout(function(){	navigate('/kycalready') }, 500);
			}else{
				setTimeout(function(){	navigate('/personal') }, 500);
			}
		}
		
    }
    return(
		<div className="container-fluid intro d-flex flex-column" >
			<div className="row">
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">				
					<div className="row top_divs">
						<div className="cards">
						<div className="logo_div intro text-center">
                                    <img src={brandlogo} alt="img-responsive logo_img" />
                                </div>
							<div className="card-body">		
								<div className="container_intro">
									<h2 className="title_head">To continue, we need to <b>verify your identity</b></h2>
									<ul className="intr">
										<li>Before  you start, please prepare your identity document and make sure it is valid.</li>
										<li>We also require you to agree to our process of your personal data..</li>
									</ul>
									<div className="collect-steps ">
										<div className="step-row">
											<div className="steps">		
											<h3><img src="./icon/flash.png" className="listicon"/><span className="title_step">How we verify your</span></h3>
											<p>Verification usually takes less than a few minutes and is encrypted</p>
											</div>
										</div>
										<div className="step-row">
											<div className="steps">
												<h3><img src="./icon/lock.png" className="listicon"/> <span className="title_step">How we verify your</span></h3>
												<p>To learn how our service provider uses data you provide and device data, see their <a href="">Privacy Satement</a></p>
											</div>
										</div>
									
									</div>
									
									<div className="row">
										<div className="select_btn home_btn">
											<button className="btn_continue" onClick={nextpage} >{btntxt}
												<i className={btnicon} ></i>
											</button>
										</div>
									</div>
									
									<div className="row footer">
										<div className="collect-steps_1">{brandname}</div>
									</div>
								</div>
							</div>									
						</div>									
					</div>									
				</div>
			</div>
		</div>	
    );
};
export default Intro;