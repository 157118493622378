import './App.css';
import Intro from './pages/intro/Intro';
import User from './pages/user/user';
import Home from './pages/home';
import Passport from './pages/passport';
import IdproofType from './pages/idproofType';
import Idproofback from './pages/idproofback';
import ProffAdress from './pages/proofAddress';
import Recognition from './pages/Recognition';
import axios from "axios";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import React, { useRef, useEffect, useState,Component } from 'react';
import {BrowserRouter as Router , Route ,Routes } from 'react-router-dom';
import Donepage from './pages/done';

localStorage.setItem('userAgent', window.navigator.userAgent);
localStorage.setItem('brandlogo', 'https://res.cloudinary.com/hashfort/image/upload/v1682595042/logo/ictgvexi7nnzfe7sweff.png');
localStorage.setItem('brandname', '{brandname}');
localStorage.setItem('webtitle', 'Codego KYC');
localStorage.setItem('faviconUrl', 'https://res.cloudinary.com/durotechindustries-com-au/image/upload/v1724931624/codegofav_doqhae.ico');


function App() {
	const webtitle = localStorage.getItem('webtitle') || 'Default Title';
	const faviconUrl = localStorage.getItem('faviconUrl') || '%PUBLIC_URL%/favicon.ico';
  	const [state,setstatus]=React.useState(0);
	const [apierror,setapierror]=React.useState(0);
  	const [message,setmessage]=React.useState('');
	const senddata = async()=> {		
		const queryParams = new URLSearchParams(window.location.search)
		let uuid = queryParams.get("uuid");
		
		if(uuid === undefined || uuid === null) 
		{	
			uuid = localStorage.getItem('uuid');	
		}					
		let data = JSON.stringify({
			"uuid": uuid,
		});		
		let config = {
			method: 'POST',
			mode: 'cors',	
			url: 'https://kyc2.codegotech.com/chcekuuid',
			headers: {
				'Content-Type': 'application/json',				
			},
			data : data
		};		
		await axios.request(config)
		.then((response) => {
			
			if(response.status == 200)
			{				
				if(response.data.status == 1)
				{		
					localStorage.setItem('isalreadycheck', response.data.is_already_check);	
					localStorage.setItem('brandlogo', response.data.brandlogo);
					localStorage.setItem('brandname', response.data.brandname);			
					localStorage.setItem('kyctype', response.data.type);
					localStorage.setItem('uuid', uuid);
					setstatus(response.data.status);	
					if(response.data.type == 'partial')
					{
						if(response.data.liveness === 1)
						{
							localStorage.setItem('comImage',  response.data.comImage);
							localStorage.setItem('partial_page', 'LIVENESS');
						}else if(response.data.id_proof === 1)
						{
							localStorage.setItem('partial_page', 'ID');
						}else if(response.data.address_proof === 1)
						{
							localStorage.setItem('partial_page', 'ADDRESS');
							
						}						
					}else{	
						localStorage.setItem('address_proof', response.data.address_proof);
					}
				}else{		
					setstatus(response.data.status);
					setmessage(response.data.message);
				}				
			}else{
				setstatus(0);
				setmessage('unauthorized access');
			} 

		}).catch((error) => {
			console.log(error);
		});  
		
	}
	const uuid = localStorage.getItem('uuid');
  	const brandlogo = localStorage.getItem('brandlogo');
	let qruurl = 'https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=https://kyc.codegotech.com/?uuid='+uuid+'&choe=UTF-8&chld=L';
	
	useEffect(() => {
        document.title = webtitle;
		const favicon = document.getElementById('favicon');
        if (favicon) {
            favicon.href = faviconUrl;
        }
        senddata();
    }, [webtitle, faviconUrl]);
	
  return (
    <div className="App">			
				
    {isMobile == true ?
    
    state == 1 ?  
    <MobileView>
      { <Router>
        <Routes>
        <Route path='/' element={<Intro />} />
		<Route path='/kycalready' element={<User />} />
		<Route path='/personal' element={<Home />} />
		<Route path='/idprooftype' element={<IdproofType />} />
		<Route path='/Passport' element={<Passport />} />
		<Route path='/idproofback' element={<Idproofback />} />
		<Route path='/proof' element={<ProffAdress />} />
		<Route path='/recognition' element={<Recognition />} />
		<Route path='/process' element={<Donepage />} />
        </Routes>        
      </Router>  }
    </MobileView>
    : <div className="titlemsg">
		<div className='warning'>
      <img src='./icon/close_icon.webp' alt="Codego warning sign" />							
      </div>{message}</div>
    :
    <BrowserView>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 offset-md-3">				
          <div className="row home_top_div">					
            <div className="logo_div text-center">
              <img src={brandlogo} className="img-responsive logo_img"/>
            </div>
            
            <h1>
            {
              state == 1 ?  
              
              <div className="card">
                <div className="card-header text-center">							
                  <div className="title">Verify Yourself Scan QR</div>								
                </div>
                <div className="card-body card_hd">	
                  <h3 className="card_head">Start identity verification on your <br/>mobile phone</h3>
                  <h3 className="card_p">Please scan the provided QR code on your mobile device to continue the process. Using your mobile phone will speed up and simplify the verification process.</h3>
                  <div className="">
                    <img src={qruurl} alt="" />
                  </div>
                </div>
              </div>
              : <div className="title_msg">
            <div className='close_circle'>
              <img src='./icon/close_icon.webp
              ' alt="Codego warning sign" />							
            </div>
            {message}
            </div>
            }
            </h1>
          </div>  
        </div>  
      </div>  
    </div>  
    </BrowserView> 
    }
  </div>
    
  );
}

export default App;
