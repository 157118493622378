// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title{
    font-weight: 600;
    font-size: 16px;
}
.form-label{
    margin-bottom: .5rem;
    font-weight: 600;
}
.select_div_label{
    padding: 10px 5% 20px;
    text-align: left;
}

.selectDrop {
    width: 100%;
    line-height: 20px;
    padding: 9px 14px;
}`, "",{"version":3,"sources":["webpack://./src/pages/user/user.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":[".title{\n    font-weight: 600;\n    font-size: 16px;\n}\n.form-label{\n    margin-bottom: .5rem;\n    font-weight: 600;\n}\n.select_div_label{\n    padding: 10px 5% 20px;\n    text-align: left;\n}\n\n.selectDrop {\n    width: 100%;\n    line-height: 20px;\n    padding: 9px 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
