import {React,  useState ,useEffect,useRef} from "react";
import { useNavigate  } from "react-router-dom";
import * as  FormData from "form-data";
import   axios from "axios";
import './passport.css';
import 'bootstrap/dist/css/bootstrap.css';
const ProffAdress = () => {
	const inputRef = useRef(null);
	const brandname = localStorage.getItem('brandname');
	const [isMobile, setIsMobile] = useState(true);
	const navigate = useNavigate();
    const [file, setFile] = useState();
    const [visible, setVisible] = useState(false);
	const [disabledbtn, setdisabled] = useState(false);
	const [selvisible, setSelvisible] = useState(true);
	
	const [btn , setbtn] = useState("btn btn-primary");
	const [btntxt , setbtntxt] = useState("Continue");
	const [btnicon , setbtnicon] = useState("fa-solid fa-arrow-right");
	
		let photo = '';
		const [info , setinfo]=useState({
			image : ''
		});
		
		const partial_page = localStorage.getItem('partial_page');
		const kyctype = localStorage.getItem('kyctype');
		const getData = async () => {
			const res = await axios.get("https://api.ipify.org/?format=json");
			// console.log(res.data);
			localStorage.setItem('Ip-Adrees', res.data.ip);
		};
		//console.log(kyctype);	
		const retake= ()=>{
			setinfo({
				id:'proof-address',
				image :photo
			});
			setFile('');
			setSelvisible(true);
			setVisible(false);
			setdisabled(false);
			
			setbtntxt("Continue");
			setbtn("btn btn-primary");
			setbtnicon("fa-solid fa-arrow-right");
		}
		const nextpage= ()=>{
			setbtntxt("Uploading");
			setbtn("btn btn-success");
			setbtnicon("fa-solid fa-check");
			setdisabled(true);
			setTimeout(function(){ setdisabled(false); },10000);
			saveinternal();			
		}
		const clicktoselect = ()=>{
			inputRef.current.click()
		}
		const saveinternal = async () => {
      
			let datas = JSON.stringify({
			  "uuid": localStorage.getItem('uuid'),
			  "image": info.image,
			  "type": 'address',
			});
			let config = {
				  method: 'post',
				 'mode':'cors',
				  url: 'https://kyc2.codegotech.com/save_image',
				  headers: {
					'Content-Type': 'application/json'
				  },
				  data : datas
			};			
			axios.request(config).then((response) => {		  
			if(response.status===200)
			{
				
				if(response.data.status === 1)
				{										
					if(kyctype === 'partial' && partial_page === 'ADDRESS')
					{
						
						let data = JSON.stringify({
							"uuid":localStorage.getItem('uuid'),
							"kyctype": kyctype,							
							"partial_page": partial_page,							
							"userAgent": localStorage.getItem('userAgent'),							
							"ip_address": localStorage.getItem('Ip-Adrees'),							
							"proofphoto": response.data.image
						});
						let config = {
							  method: 'post',
							 'mode':'cors',
							  url: 'https://kyc2.codegotech.com/send_userdata',
							  headers: {
								'Content-Type': 'application/json'
							  },
							  data : data
						};						
						axios.request(config).then((response) => {
							//console.log(response.status);
							if(response.status === 200)
							{				
								localStorage.removeItem('userAgent');								
								localStorage.removeItem('Ip-Adrees');
								localStorage.removeItem('proofPhoto');								
								localStorage.removeItem('uuid');
								navigate('/process');			
							}		  
						}).catch((error) => {
							console.log(error);
						}); 
					}else{
						localStorage.setItem('proofPhoto', response.data.image);	
						navigate('/Recognition')
					}				
				}else{
					setVisible(false);
					let html = 'Please re-upload proof address.';
					let mrz_data_element = document.getElementById('mrz-data')
					mrz_data_element.innerHTML =  html
				}
			}		  
			})
			.catch((error) => {
			  console.log(error);
			});
		}
    
    useEffect(() => {
		let uuids = localStorage.getItem('uuid');	
		if(uuids === null) 
		{	
			navigate('/');
		}
		getData();
    }, [])
	return (
	<div className="container-fluid">
        {!isMobile && (  
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="alert-warning" >
		  	<img src='./icon/close_icon.webp' alt="Codego warning sign" />
        </div>
            <div className=" alert-warning" >
              This application is only accessible from mobile devices. Please switch to a mobile device to continue.
            </div>
          </div>
        </div>
      )}

      {isMobile && (
		<div className="row">
			<div className="col-md-12 col-sm-12 col-xs-12">				
				<div className="row top_div">					

					<div className="card">
						<div className="card-header text-center">							
							<div className="title">Proof of Address</div>
							<div id='mrz-data'></div>						
							<small>Make sure that all the information on the photo is visible and easy to read</small>
						</div>							
						<div className="card-body text-center">
							<div className="">
								<div className='passport-photod'>
								<div className="image-passport" ><img src={file} style={{ width: '300px', height: 'auto' }} accept="image/*" /></div>
									{selvisible  && <div id="start">
									<i className="fa fa-upload" aria-hidden="true"></i>
									<div>Select a file</div>
									<div id="notimage" className="hidden">Please select an image</div>
									
									<button className="btn btn-primary mt-3 mb-3" onClick={  clicktoselect }><i className="fa-solid fa-id-card"></i> Select File</button>
									
									<input type="file" accept="image/*"  id="photo"  style={{display: 'none'}} ref={inputRef} className="custom-file-input" onChange={(evt)=>{
									setVisible(false);
									let mrz_data_element = document.getElementById('mrz-data')
									mrz_data_element.innerHTML = '';
									var reader = new  FileReader();
									reader.onload = function(e){
									photo = e.target.result;
									setinfo({
									image :photo
									});
									}
									if(evt.target.files.length){
										reader.readAsDataURL(evt.target.files[0]);
									}
									evt.preventDefault()
									let myFile = evt.target.files[0]
									if (!myFile) { return }
									let data = new FormData();
									data.append("document", myFile, myFile.name);
									setFile(URL.createObjectURL(evt.target.files[0]));
									setSelvisible(false);
									setVisible(true);
									} }/>
									</div>}
									{visible  && <div id="start_1">
										<div className="row mt-3 mb-3">
											<div className="col-6">
												<div > <button disabled={disabledbtn}  className="btn btn-primary" onClick={ retake }>Retake <i className="fa-solid fa-repeat"></i></button> </div> 	
											</div>
											<div className="col-6">
												<div ><button className={btn}  disabled={disabledbtn} onClick={nextpage} style={{ height: '45px' }}>{btntxt} <i className={btnicon}></i></button></div> 	
											</div>
										</div>	
									</div> } 
									<div id='mrz-data'></div>
									<div className="clrboth"></div>	
									
							</div>		
							</div>
						</div>
					</div>
                    <div className="title">Proof of Address</div>
                    <div className="">
                        <ol className="list_proof">
                            <li>Utility Bill (less then 3 months)</li>
                            <li>Bank Account Statement(less then 3 months)</li>
                            <li>Certificate of residence (released by the municipality)</li>
                        </ol>
                    </div>
					<div className="row footer">	
						<div className="collect-steps_1">{brandname}</div>
					</div>
					
				</div>
			</div>
		</div>
        )}
	</div>
	);
}
export default ProffAdress;