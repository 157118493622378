// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-fluid {
max-width: 100%;
height: auto;
padding: 5px;
}
.example_passport{ 
margin: 20px 0;
}
.passport-photo{
text-align: center;     
}
.example_pic{ 
width:100%; 
max-height: 300px;
object-fit: fill;
}
.btn-upload {
width: 80%;  
}

.btn_retake {
    background-color: red;
    color: white;
    height: 40px;
    width: 100px;
    border-radius: 12px;
    border: none;
    padding: 0 10px;
    margin-right: 5px;
    margin-top: 10px;
}
.box-btnProof{
  display: flex;
  gap: 5px;
  justify-content: center;
}


.upload{
    background-color: #007bff;
}





`, "",{"version":3,"sources":["webpack://./src/pages/passport.css"],"names":[],"mappings":"AAAA;AACA,eAAe;AACf,YAAY;AACZ,YAAY;AACZ;AACA;AACA,cAAc;AACd;AACA;AACA,kBAAkB;AAClB;AACA;AACA,UAAU;AACV,iBAAiB;AACjB,gBAAgB;AAChB;AACA;AACA,UAAU;AACV;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;AACA;EACE,aAAa;EACb,QAAQ;EACR,uBAAuB;AACzB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".img-fluid {\nmax-width: 100%;\nheight: auto;\npadding: 5px;\n}\n.example_passport{ \nmargin: 20px 0;\n}\n.passport-photo{\ntext-align: center;     \n}\n.example_pic{ \nwidth:100%; \nmax-height: 300px;\nobject-fit: fill;\n}\n.btn-upload {\nwidth: 80%;  \n}\n\n.btn_retake {\n    background-color: red;\n    color: white;\n    height: 40px;\n    width: 100px;\n    border-radius: 12px;\n    border: none;\n    padding: 0 10px;\n    margin-right: 5px;\n    margin-top: 10px;\n}\n.box-btnProof{\n  display: flex;\n  gap: 5px;\n  justify-content: center;\n}\n\n\n.upload{\n    background-color: #007bff;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
